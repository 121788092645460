import * as Sentry from "@sentry/browser";
import { graphql, useStaticQuery } from "gatsby";
import { first, max, min } from "lodash";
import { useEffect, useState } from "react";

// NOTE: A simple function that checks if the data from Contentful is valid
// Since it's an open CMS therefore it is likely to contain invalid entries
const isProductEntryValid = ({ node: product }) => {
  if (
    !product.skus ||
    !product.skus.length ||
    !product.skus.every((sku) => sku.price && sku.currency)
  ) {
    Sentry.captureMessage(`Found invalid product entry ${product.id}`, "error");
    return false;
  }
  return true;
};

// TODO: Optimise: this query is used in both graphql.js and Product.js.
export const useListProducts = () => {
  const [loading, setLoading] = useState(true);

  const data = useStaticQuery(LIST_PRODUCTS);
  const products = data.allContentfulProduct.edges
    .filter(isProductEntryValid)
    .map((product) => {
      const {
        node: {
          id,
          title,
          slug,
          node_locale,
          contentful_id,
          description,
          images,
          seller,
          website,
          platform,
          skus,
          categories,
        },
      } = product;

      const filteredSkus = skus.map((sku) => {
        const { price, props, currency, contentfulid } = sku;
        return {
          currency,
          variants: props,
          id: contentfulid,
          price: price * 100,
        };
      });

      const urls =
        images
          .filter((image) => image.file && image.file.url)
          .map((image) => image.file.url) || [];

      const loadingUrls =
        images
          .filter((image) => image.resize && image.resize.src)
          .map((image) => image.resize.src) || [];

      const prices = filteredSkus.map((sku) => sku.price);
      const priceArr =
        min(prices) === max(prices)
          ? [min(prices)]
          : [min(prices), max(prices)];

      return {
        id,
        title,
        slug,
        seller,
        categories,
        description,
        node_locale,
        contentful_id,

        currency: first(filteredSkus).currency,
        platform,

        sellerLink: website,
        skus: filteredSkus,
        price: priceArr,
        images: urls,
        loadingImages: loadingUrls,
      };
    });

  useEffect(() => {
    if (data) setLoading(false);
  }, [data]);

  return { products, loading };
};

const LIST_PRODUCTS = graphql`
  {
    allContentfulProduct(filter: { platform: { eq: "Wet Market" } }) {
      edges {
        node {
          id
          title
          slug
          node_locale
          contentful_id
          seller
          # FIXME: gatsby requires at least one value for each field
          # For the Mmucho Wet Market phase, 'website' is not used
          # website
          platform
          description {
            description
          }
          images {
            file {
              url
            }
            resize(quality: 0, width: 1, toFormat: JPG) {
              src
            }
          }
          skus {
            price
            props
            currency
            id
            contentfulid
          }
          categories {
            hierarchyLevel
            title
            slug
          }
        }
      }
    }
  }
`;
