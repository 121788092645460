import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { addToCart } from "../../state/cart";
import { addToSaved, removeFromSaved } from "../../state/saved";
import BlockButton from "../BlockButton";

const iconStyle = { style: { fontSize: 25, color: "#EC4F26" } };

export const AddToCart = (props) => {
  const { lineItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <BlockButton
      disabled={!lineItem}
      onClick={() => dispatch(addToCart(lineItem))}
    >
      {t("product.addToCart")}
    </BlockButton>
  );
};

export const AddToSaved = (props) => {
  const { product, className } = props;
  const { title } = product;

  const dispatch = useDispatch();
  const savedState = useSelector((state) => state.saved);
  const isSaved =
    savedState && savedState.map((product) => product.title).includes(title);

  return (
    <Button
      className={className}
      type="link"
      onClick={() =>
        isSaved
          ? dispatch(removeFromSaved(product))
          : dispatch(addToSaved(product))
      }
    >
      {isSaved ? (
        <HeartFilled {...iconStyle} />
      ) : (
        <HeartOutlined {...iconStyle} />
      )}
    </Button>
  );
};
