import { Card, Col, Space, Tag } from "antd";
import { Link } from "gatsby";
import { first, isArray, last } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  selectCurrentExchangeRates,
  selectPreferredCurrency,
} from "../state/app";
import { formatCurrency } from "../utils/helpers";
import styles from "./css/productCard.module.scss";
import { AddToSaved } from "./Product/Actions";

const { Meta } = Card;

const ProductCard = ({ product, fallback }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [loading, setLoading] = useState(true);

  const rates = useSelector(selectCurrentExchangeRates);
  const preferredCurrency = useSelector(selectPreferredCurrency);

  const {
    title,
    price,
    currency,
    slug,
    loadingImages,
    images,
    categories,
  } = product;

  const category = last(categories);

  const priceDisplay = isArray(price)
    ? price
        .map((p) =>
          formatCurrency(p / rates[currency], preferredCurrency, language)
        )
        .join(" - ")
    : formatCurrency(price / rates[currency], preferredCurrency, language);

  const imgFromArr = (images) => (isArray(images) ? first(images) : images);
  const imagesDisplay =
    images.length > 0 ? imgFromArr(images) : imgFromArr(fallback?.images);

  const loadingImagesDisplay = loadingImages
    ? imgFromArr(loadingImages)
    : imgFromArr(fallback?.loadingImages);

  return (
    <Col span={12} md={5}>
      <Card
        hoverable
        size="small"
        className={styles.card}
        cover={
          <Link to={`/product/${slug || fallback?.slug}`}>
            {loading && (
              <img
                alt={title}
                src={imagesDisplay}
                style={{ display: "none" }}
                onLoad={() => setLoading(false)}
              />
            )}
            {!loading && (
              <div
                className={styles.cover}
                style={{
                  position: "absolute",
                  width: "inherit",
                  backgroundImage: `url("${imagesDisplay}")`,
                }}
              />
            )}
            <div
              className={styles.cover}
              style={{
                backgroundImage: `url("${loadingImagesDisplay}")`,
              }}
            />
          </Link>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {category && (
            <Link to={`/app/search?categories=${category.slug}`}>
              <Tag>{category.title}</Tag>
            </Link>
          )}
          <Meta
            title={
              <Link to={`/product/${slug || fallback?.slug}`}>{title}</Link>
            }
            description={priceDisplay}
          />
        </Space>
        <AddToSaved product={product} className={styles.favourite} />
      </Card>
    </Col>
  );
};

// @FIXME Refactor the case (if fallback language isn't available) to parent component.

export default ({ product, fallback }) => {
  if (!product && !fallback) {
    return (
      <Col span={12} md={5}>
        <Card size="small" className={styles.card} loading={true} />
      </Col>
    );
  }
  return <ProductCard product={product || fallback} fallback={fallback} />;
};
